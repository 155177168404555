import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getClientStatistics } from '../../actions/clientActions';
import ClientStadisticCards from '../ClientStadisticCards';
import Loader from '../Loader';
import Message from '../Message';

const UserCllentsCountStadistics = () => {
  const dispatch = useDispatch();

  const clientStatistics = useSelector((state) => state.clientStatistics);
  const { loading, error, statistics } = clientStatistics;

  useEffect(() => {
    dispatch(getClientStatistics());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  const { totalClients, updatedClients, pendingClients, percentageUpdated } =
    statistics;

  if (error) {
    <Col xs={12} className="py-3">
      <Message title="Error de sistema" variant="danger">
        <p>No se ha podido obtener estadísticas de usuarios</p>
        <p>Detalles:</p>
        <p>{error}</p>
      </Message>
    </Col>;
  }

  return (
    <Row>
      <ClientStadisticCards
        totalClients={totalClients}
        updatedClients={updatedClients}
        pendingClients={pendingClients}
        percentageUpdated={percentageUpdated}
      />
    </Row>
  );
};

export default UserCllentsCountStadistics;
