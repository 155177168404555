import React from 'react';
import { Card, Col, Placeholder } from 'react-bootstrap';

const CardPlaceholder = () => {
  return (
    <Col xs={12} md={4}>
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={3} /> <Placeholder xs={4} />{' '}
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={5} />
          </Placeholder>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CardPlaceholder;
