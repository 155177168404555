import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, Image, NavDropdown } from 'react-bootstrap';
import { logout } from '../actions/userActions';
import logo from '../resources/logo.png';

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">
            <Image src={logo} height={80} width="auto" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Inicio</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>Acerca de</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              <NavDropdown
                title={`${userInfo.user.name} ${userInfo.user.lastname}`}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/my-account">
                  Mi perfil
                </NavDropdown.Item>
                {userInfo &&
                  (userInfo.user.role === 'ADMINISTRATIVO' ||
                    userInfo.user.role === 'SYSADMIN') && (
                    <NavDropdown.Item href="/admin/clients">
                      Clientes
                    </NavDropdown.Item>
                  )}
                {userInfo &&
                  (userInfo.user.role === 'ADMINISTRATIVO' ||
                    userInfo.user.role === 'SYSADMIN') && (
                    <NavDropdown.Item href="/admin/users">
                      Usuarios
                    </NavDropdown.Item>
                  )}
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutHandler}>
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
