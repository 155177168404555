import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getClientStadisticsDaily } from '../actions/clientActions';
import Loader from './Loader';
import Message from './Message';

const DailyUpdatesLineChart = () => {
  const dispatch = useDispatch();

  const d3Chart = useRef();

  const clientStadisticsDaily = useSelector(
    (state) => state.clientStadisticsDaily
  );

  const { loading, error, dailyStatistics } = clientStadisticsDaily;

  const dimensions = {
    width: 600,
    height: 200,
    marginTop: 8,
  };

  const xAccessor = (d) => d.date;
  const yAccessor = (d) => d.count;

  const draw = (data) => {
    const wrapper = d3.select(d3Chart.current);

    const svg = wrapper
      .select('[data-chart]')
      .append('svg')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height)
      .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`);

    const xDomain = d3.extent(data, xAccessor);
    const yDomain = [0, d3.max(data, yAccessor)];

    const formatDate = d3.timeFormat('%d-%m-%Y');

    const getText = (d) => `${formatDate(d)}`;

    const xScale = d3.scaleTime().domain(xDomain).range([0, dimensions.width]);

    const yScale = d3
      .scaleLinear()
      .domain(yDomain)
      .range([dimensions.height, dimensions.marginTop]);

    /* Area */
    const areaGenerator = d3
      .area()
      .x((d) => xScale(xAccessor(d)))
      .y1((d) => yScale(yAccessor(d)))
      .y0(dimensions.height)
      .curve(d3.curveBumpX);

    svg
      .append('path')
      .datum(data)
      .attr('d', areaGenerator)
      .attr('fill', 'var(--fill)');

    /* Line */
    const lineGenerator = d3
      .line()
      .x((d) => xScale(xAccessor(d)))
      .y((d) => yScale(yAccessor(d)))
      .curve(d3.curveBumpX);

    svg
      .append('path')
      .datum(data)
      .attr('d', lineGenerator)
      .attr('stroke', 'var(--stroke)')
      .attr('stroke-width', 5)
      .attr('stroke-linejoin', 'round')
      .attr('fill', 'none');

    /* Markers */
    const markerLine = svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', dimensions.height)
      .attr('stroke-width', 3)
      .attr('stroke', 'var(--marker, var(--stroke))')
      .attr('opacity', 0);

    const markerDot = svg
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 8)
      .attr('fill', 'var(--marker, var(--stroke))')
      .attr('opacity', 0);

    /* Bisector */
    const bisect = d3.bisector(xAccessor);

    /* Events */
    svg.on('mousemove', (e) => {
      const [posX] = d3.pointer(e);
      const date = xScale.invert(posX);

      const index = bisect.center(data, date);
      const d = data[index];

      const x = xScale(xAccessor(d));
      const y = yScale(yAccessor(d));

      markerLine.attr('x1', x).attr('x2', x).attr('opacity', 1);

      markerDot.attr('cx', x).attr('cy', y).attr('opacity', 1);

      d3.select('[data-heading]').text(getText(xAccessor(d)));
      d3.select('[data-total]').text(yAccessor(d));
    });

    svg.on('mouseleave', () => {
      const lastDatum = data[data.length - 1];

      markerLine.attr('opacity', 0);
      markerDot.attr('opacity', 0);

      d3.select('[data-heading]').text('Actualizaciones Diarias');
      d3.select('[data-total]').text(yAccessor(lastDatum));
    });
  };

  const sortData = (data) => {
    return data
      .map((d) => {
        return {
          ...d,
          date: new Date(`${d._id}T12:00:00Z`),
        };
      })
      .sort((a, b) => d3.ascending(a._id, b._id));
  };

  useEffect(() => {
    dispatch(getClientStadisticsDaily());
  }, []);

  useEffect(() => {
    if (dailyStatistics) {
      draw(sortData(dailyStatistics));
    }
  }, [clientStadisticsDaily]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    <Col xs={12} className="py-3">
      <Message title="Error de sistema" variant="danger">
        <p>No se ha podido obtener estadísticas de usuarios</p>
        <p>Detalles:</p>
        <p>{error}</p>
      </Message>
    </Col>;
  }

  return (
    <div className="d-flex flex-row">
      <div className="chart-wrapper lineChart py-3" ref={d3Chart}>
        <div>
          <h3 data-heading>Actualizaciones Diarias</h3>
          <p data-total>
            {dailyStatistics && dailyStatistics.length > 0
              ? dailyStatistics[dailyStatistics.length - 1].count
              : 0}
          </p>
        </div>
        <figure data-chart />
      </div>
    </div>
  );
};

export default DailyUpdatesLineChart;
