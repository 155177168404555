import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../components/PageContainer';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { USER_PASSWORD_UPDATE_RESET } from '../constants/userConstants';
import { updateUserPassword } from '../actions/userActions';

const MyAccountPage = () => {
  const newPasswordRef = useRef();
  const confirmPassRef = useRef();
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [oldPassword, setoldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();

  const userUpdatePPassword = useSelector((state) => state.userUpdatePPassword);
  const { loading, success, error } = userUpdatePPassword;

  useEffect(() => {
    dispatch({ type: USER_PASSWORD_UPDATE_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (success === true) {
      setMessage('Datos actualizados correctamente.');
      setoldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setValidated(false);
    }
  }, [dispatch, success]);

  const passwordChangeHandler = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value === confirmPassword) {
      e.target.setCustomValidity('');
      confirmPassRef.current.setCustomValidity('');
    } else {
      e.target.setCustomValidity('Las contraseñas no coinciden');
      confirmPassRef.current.setCustomValidity('Las contraseñas no coinciden');
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword === e.target.value) {
      e.target.setCustomValidity('');
      newPasswordRef.current.setCustomValidity('');
    } else {
      e.target.setCustomValidity('Las contraseñas no coinciden');
      newPasswordRef.current.setCustomValidity('Las contraseñas no coinciden');
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(
        updateUserPassword({
          oldPassword: oldPassword.trim(),
          newPassword: newPassword.trim(),
        })
      );
    }
    setValidated(true);
  };

  return (
    <PageContainer>
      <main>
        <Container>
          <Row>
            <Col xs={12}>
              <section className="heading pt-5 pb-3 px-3">
                <h1>Mi perfil</h1>
                <p>Aquí es posible actualizar la contraseña de la cuenta.</p>
              </section>
            </Col>
            <Col xs={12}>
              {error && (
                <Message variant="danger">
                  Error al actualizar datos. Verifique con la contraseña actual
                  sea la correcta y la nueva contrasela sea diferente a la
                  actual.
                </Message>
              )}
              {message && <Message variant="success">{message}</Message>}
            </Col>
            <Col xs={12}>
              {loading ? (
                <Loader />
              ) : (
                <section className="form">
                  <Form
                    style={{ textAlign: 'left' }}
                    className="px-3"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-3" controlId="formOldPassword">
                      <Form.Label>Contraseña actual</Form.Label>
                      <Form.Control
                        value={oldPassword}
                        onChange={(e) => setoldPassword(e.target.value)}
                        type="password"
                        placeholder="Contraseña actual"
                        required
                        minLength={8}
                        maxLength={100}
                      />
                      <Form.Control.Feedback type="invalid">
                        Minimo 8 carácteres y 100 como máximo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formNewPassword">
                      <Form.Label>Nueva Contraseña</Form.Label>
                      <Form.Control
                        value={newPassword}
                        onChange={(e) => passwordChangeHandler(e)}
                        type="password"
                        placeholder="Nueva Contraseña"
                        required
                        ref={newPasswordRef}
                        minLength={8}
                        maxLength={100}
                      />
                      <Form.Control.Feedback type="invalid">
                        Las contraseñas deben coincidir. Minimo 8 carácteres y
                        100 como máximo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <PasswordStrengthBar password={newPassword} />
                    <Form.Group
                      className="mb-3"
                      controlId="formConfirmPassword"
                    >
                      <Form.Label>Confirma contraseña</Form.Label>
                      <Form.Control
                        value={confirmPassword}
                        onChange={(e) => confirmPasswordChangeHandler(e)}
                        type="password"
                        placeholder="Repetir Contraseña"
                        required
                        ref={confirmPassRef}
                        minLength={8}
                        maxLength={100}
                      />
                      <Form.Control.Feedback type="invalid">
                        Las contraseñas deben coincidir. Minimo 8 carácteres y
                        100 como máximo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Button variant="primary" type="submit">
                        Aceptar
                      </Button>
                    </Form.Group>
                  </Form>
                </section>
              )}
            </Col>
          </Row>
        </Container>
      </main>
    </PageContainer>
  );
};

export default MyAccountPage;
