import React from 'react';
import { Card, Col } from 'react-bootstrap';

const ClientStadisticCards = ({
  totalClients = 0,
  updatedClients = 0,
  pendingClients = 0,
  percentageUpdated = 0,
}) => {
  const numberFormatter = Intl.NumberFormat('es');

  return (
    <>
      <Col xs={12} md={3}>
        <Card>
          <Card.Body>
            <p className="h5" style={{ color: '#2980b9' }}>
              Total Clientes
            </p>
            <p>{numberFormatter.format(totalClients)}</p>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={3}>
        <Card>
          <Card.Body>
            <p className="h5" style={{ color: '#16a085' }}>
              Clientes Act.
            </p>
            <p>{numberFormatter.format(updatedClients)}</p>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={3}>
        <Card>
          <Card.Body>
            <p className="h5" style={{ color: '#f39c12' }}>
              Clientes Pendientes
            </p>
            <p>{numberFormatter.format(pendingClients)}</p>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={3}>
        <Card>
          <Card.Body>
            <p className="h5" style={{ color: '#8e44ad' }}>
              Clientes Act. %
            </p>
            <p>{`${numberFormatter.format(percentageUpdated)}%`}</p>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default ClientStadisticCards;
