import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  USER_REGISTER_RESET,
  USER_UPDATE_RESET,
} from '../../constants/userConstants';
import ToastBox from '../../components/ToastBox';
import UserStatistics from '../../components/users/UserStatistics';
import UserFilters from '../../components/users/UserFilters';

const UserListtab = () => {
  const dispatch = useDispatch();

  const [notifStatus, setNotifStatus] = useState();

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { error: errorUpdate, success: successUpdate } = userUpdate;

  const userRegister = useSelector((state) => state.userRegister);
  const { error: errorRegister, userRegistered } = userRegister;

  useEffect(() => {
    if (successUpdate || userRegistered) {
      setNotifStatus({
        message: 'Los datos guardados correctamente..',
        type: 'success',
      });
    }
  }, [successUpdate, userRegistered]);

  useEffect(() => {
    if (errorUpdate || errorRegister) {
      setNotifStatus({
        message: 'No se pudieron guardar los datos.',
        type: 'danger',
      });
    }
  }, [errorUpdate, errorRegister]);

  useEffect(() => {
    dispatch({ type: USER_UPDATE_RESET });
    dispatch({ type: USER_REGISTER_RESET });
  }, [dispatch]);

  const registerUserHandler = () => {
    navigate('/admin/register');
  };

  const updateUserCount = () => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    axios.post(`/api/client/updateUsers`, {}, config);
  };

  return (
    <Row>
      <Col xs={12}>
        <ToastContainer
          position="bottom-end"
          className="p-5 position-fixed"
          style={{ position: 'fixed', bottom: 0 }}
        >
          {notifStatus && (
            <ToastBox
              message={notifStatus.message}
              type={notifStatus.type}
              key={new Date().toString()}
            />
          )}
        </ToastContainer>
      </Col>
      <Col xs={12}>
        <section className="heading pt-3 pb-3 px-3">
          <h1>Usuarios</h1>
          <p>
            Estos son los usuarios que se han registrado en el sistema. Es
            posible desahilitar el acceso a los usuarios en caso de ser
            necesario.
          </p>
        </section>
      </Col>
      <UserStatistics />
      <Col xs={12} style={{ textAlign: 'right' }}>
        <Button className="my-3" onClick={registerUserHandler}>
          <i className="fas fa-plus" />
          &nbsp;Nuevo Usuario
        </Button>
        <Button className="m-3" onClick={updateUserCount}>
          Actualizar datos
        </Button>
      </Col>
      <UserFilters />
    </Row>
  );
};

export default UserListtab;
