import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import loginImage from '../resources/loginImage1.jpg';
import LoginForm from '../components/LoginForm';
import RecoveryForm from '../components/RecoveryForm';

const LoginPage = () => {
  const [showRecovery, setShowRecovery] = useState(false);

  return (
    <main>
      <Row style={{ maxWidth: '100%' }}>
        <Col
          className="d-none d-md-block col-md-6 col-lg-8"
          style={{
            background: `url(${loginImage}) 50%/cover no-repeat`,
            minHeight: '100vh',
          }}
        />
        <Col xs={12} md={6} lg={4} className="text-center">
          {showRecovery ? (
            <RecoveryForm onRecovery={() => setShowRecovery(false)} />
          ) : (
            <LoginForm onRecovery={() => setShowRecovery(true)} />
          )}
        </Col>
      </Row>
    </main>
  );
};

export default LoginPage;
