import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import bgImage from '../resources/loginImage2.jpg';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const redirectHomeHandler = () => {
    navigate('/');
  };

  return (
    <main>
      <Row style={{ maxWidth: '100%' }}>
        <Col
          xs={12}
          style={{
            background: `url(${bgImage}) 50%/cover no-repeat`,
            minHeight: '100vh',
          }}
          className="d-flex flex-column justify-content-center"
        >
          <div className="p-5 text-center">
            <h1 className="display-1 fw-bold text-white">404</h1>
            <p className="fs-3 text-white"> Opps! Página no encontrada.</p>
            <p className=" text-white">La página que buscas no existe.</p>
            <Button
              variant="primary"
              type="button"
              onClick={redirectHomeHandler}
            >
              Inicio
            </Button>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default NotFoundPage;
