import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserDetails, updateUser } from '../actions/userActions';
import ErrorPage from '../components/ErrorPage';
import Loader from '../components/Loader';
import PageContainer from '../components/PageContainer';

const UserEditPage = () => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();

  const userId = params.id;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    if (successUpdate) {
      navigate('/admin/users');
    } else if (!user || user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setName(user.name);
      setLastName(user.lastname);
      setEmail(user.email);
      setUsername(user.username);
      setPhone(user.phone);
      setRole(user.role);
    }
  }, [successUpdate, user, userId, dispatch, navigate]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(
        updateUser({
          _id: userId,
          username: username.trim(),
          name: name.trim(),
          lastname: lastname.trim(),
          email: email.trim(),
          phone: phone.trim(),
          role,
        })
      );
    }
    setValidated(true);
  };

  const returnHomeHandler = () => {
    navigate('/admin/users');
  };

  if (loading || loadingUpdate) {
    return (
      <PageContainer>
        <main>
          <Container>
            <Loader />
          </Container>
        </main>
      </PageContainer>
    );
  }

  if (error || errorUpdate) {
    return (
      <PageContainer>
        <main>
          <Container>
            <ErrorPage error={error || errorUpdate} />
          </Container>
        </main>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <main>
        <Container>
          <Row style={{ maxWidth: '100%' }}>
            <Col xs={12}>
              <Breadcrumb>
                <BreadcrumbItem href="/admin/users">Usuarios</BreadcrumbItem>
                <BreadcrumbItem active>Editar</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs={12}>
              <section className="heading pt-5 pb-3 px-3">
                <h1>Editar Usuario</h1>
                <p>
                  Complete los siguientes datos para poder crear una nueva
                  cuenta Lincoln.
                </p>
              </section>
            </Col>
            <Col xs={12}>
              <section className="form">
                <Form
                  style={{ textAlign: 'left' }}
                  className="px-3"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      value={email}
                      type="email"
                      placeholder="Email"
                      required
                      maxLength={200}
                      onChange={(e) => setEmail(e.target.value)}
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio. Debe ser un correo válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type="text"
                      placeholder="Nombre de usuario"
                      required
                      minLength={8}
                      maxLength={100}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio. Debe tener entre 8 y 50 carácteres.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Nombre"
                      required
                      maxLength={200}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio. Màximo 200 carácteres.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLastname">
                    <Form.Label>Apellido</Form.Label>
                    <Form.Control
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      placeholder="Apellido"
                      required
                      maxLength={200}
                    />
                    <Form.Control.Feedback type="invalid">
                      Obligatorio. Màximo 200 carácteres.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formRole">
                    <Form.Label>Rol</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">Seleccione un rol</option>
                      <option value="VENDEDOR">Vendedor</option>
                      <option value="ADMINISTRATIVO">Administrativo</option>
                      <option value="SYSADMIN">SysAdmin</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Obligatorio.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Label>Teléfono</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        +595
                      </InputGroup.Text>
                      <Form.Control
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        placeholder="21 111 111"
                        max={100}
                        required
                        pattern="^\(?([0-9]{2,3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$"
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio. Debe de seguir el formato indicado.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formSubmit">
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="mx-2"
                      onClick={returnHomeHandler}
                    >
                      Volver
                    </Button>
                  </Form.Group>
                </Form>
              </section>
            </Col>
          </Row>
        </Container>
      </main>
    </PageContainer>
  );
};

export default UserEditPage;
