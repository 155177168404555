import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PageContainer from '../components/PageContainer';

const AboutPage = () => {
  return (
    <PageContainer>
      <main>
        <Row>
          <Col xs={12}>
            <section className="heading pt-5 pb-3 px-3">
              <h1>Clientes Lincoln</h1>
              <p>Versión: 1.1.4</p>
              <p>Fecha: 06-12-2022</p>
              <p>
                Si encuentrá algún problema, reportar al correo{' '}
                <a href="mailto:ti@lincoln.com.py">ti@lincoln.com.py</a>
              </p>
            </section>
          </Col>
        </Row>
      </main>
    </PageContainer>
  );
};

export default AboutPage;
