import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getClientStatistics } from '../../actions/clientActions';
import CardPlaceholder from '../CardPlaceholder';
import ClientStadisticCards from '../ClientStadisticCards';
import Message from '../Message';
import PieChartD3 from '../PieChartD3';

const ClientStatistics = () => {
  const dispatch = useDispatch();

  const clientStatistics = useSelector((state) => state.clientStatistics);
  const { loading, error, statistics } = clientStatistics;

  // const [fail, setFail] = useState(false);
  // const [fail, setFail] = useState(false);

  useEffect(() => {
    dispatch(getClientStatistics());
  }, [dispatch]);

  if (loading) {
    return (
      <>
        <CardPlaceholder />
        <CardPlaceholder />
        <CardPlaceholder />
      </>
    );
  }

  const { totalClients, updatedClients, pendingClients, percentageUpdated } =
    statistics;

  if (error) {
    <Col xs={12} className="py-3">
      <Message title="Error de sistema" variant="danger">
        <p>No se ha podido obtener estadísticas de usuarios</p>
        <p>Detalles:</p>
        <p>{error}</p>
      </Message>
    </Col>;
  }

  return (
    <Row>
      <ClientStadisticCards
        totalClients={totalClients}
        updatedClients={updatedClients}
        pendingClients={pendingClients}
        percentageUpdated={percentageUpdated}
      />
      <Col xs={12} md={6}>
        <PieChartD3
          title="Resumen Clientes"
          data={[updatedClients, pendingClients]}
          colors={['#1abc9c', '#f1c40f']}
          labels={['Actualizados', 'Pendientes']}
        />
      </Col>
    </Row>
  );
};

export default ClientStatistics;
