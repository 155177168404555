import React from 'react';
import { Col, Table } from 'react-bootstrap';
import Loader from '../../Loader';
import Paginate from '../../Paginate';

const ClienListByUserTable = ({
  clients = [],
  page,
  pages,
  onPageChange,
  loading,
}) => {
  const pageChangeHandler = (newPage = 1) => {
    onPageChange(newPage);
  };

  const dateFormatter = Intl.DateTimeFormat('es', {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Col xs={12} className="d-flex flex-row-reverse">
        <p>{`Pàgina ${page || 0} de ${pages || 0}`}</p>
      </Col>
      <Col xs={12}>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr className="table-dark">
              <th>Código</th>
              <th>Nombre Fantasía</th>
              <th>Razón Social</th>
              <th>Documento</th>
              <th>Actualización</th>
            </tr>
          </thead>
          <tbody>
            {clients &&
              clients.length > 0 &&
              clients.map((client) => (
                <tr key={client._id}>
                  <td>{client.code}</td>
                  <td>{client.fantasyName}</td>
                  <td>{client.businessName}</td>
                  <td>{`${client.docType || ''} - ${
                    client.documentNumber
                  }`}</td>
                  <td>
                    {client.modified
                      ? dateFormatter.format(new Date(client.updatedAt))
                      : ''}
                  </td>
                </tr>
              ))}
            {clients && clients.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">
                  Sin resultados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Paginate page={page} pages={pages} onPaginate={pageChangeHandler} />
      </Col>
    </>
  );
};

export default ClienListByUserTable;
