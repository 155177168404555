import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { login } from '../actions/userActions';
import Loader from './Loader';
import Message from './Message';
import TitleSection from './TitleSection';

const LoginForm = ({ onRecovery }) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = searchParams.get('redirect')
    ? `/${searchParams.get('redirect')}`
    : '/';

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(login(email, password));
    }
    setValidated(true);
  };

  useEffect(() => {
    if (userInfo) {
      navigate(`${redirect}`);
    }
  }, [userInfo, redirect, navigate]);

  if (loading) {
    return (
      <>
        <TitleSection
          title="Iniciar Sesión"
          description="Utilice su cuenta Lincoln para ingresar a la aplicación."
        />
        <Loader />
      </>
    );
  }

  return (
    <>
      <TitleSection
        title="Iniciar Sesión"
        description="Utilice su cuenta Lincoln para ingresar a la aplicación."
      />
      {error && (
        <Message variant="danger">Usuario o contraseña incorreta</Message>
      )}
      <section className="form">
        <Form
          style={{ textAlign: 'left' }}
          className="px-3"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Email"
              maxLength={100}
              required
            />
            <Form.Control.Feedback type="invalid">
              Obligatorio. Debe ser una dirección email válida.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Contraseña"
              maxLength={100}
              required
            />
            <Form.Control.Feedback type="invalid">
              Obligatorio
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex flex-row justify-content-center">
            <Button variant="primary" type="submit">
              Ingresar
            </Button>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <Button variant="link" onClick={onRecovery}>
              ¿Olvido su contraseña?
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default LoginForm;
