import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userDeleteReducer,
  userDetailsReducer,
  userListClientCountReducer,
  userListReducer,
  userLoginReducer,
  userRecoveryReducer,
  userRegisterReducer,
  userStatisticsReducer,
  userUpdatePPasswordReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from './reducers/userReducer';
import {
  clientByCodeReducer,
  clientListByUserReducer,
  clientListReducer,
  clientStadisticsDailyReducer,
  clientStatisticsReducer,
  clientUpdateReducer,
} from './reducers/clientReducers';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userListClientCount: userListClientCountReducer,
  clientByCode: clientByCodeReducer,
  clientUpdate: clientUpdateReducer,
  clientList: clientListReducer,
  userUpdatePPassword: userUpdatePPasswordReducer,
  userStatistics: userStatisticsReducer,
  clientStatistics: clientStatisticsReducer,
  clientStadisticsDaily: clientStadisticsDailyReducer,
  clientListByUser: clientListByUserReducer,
  userRecovery: userRecoveryReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
