import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import ClientListTab from './tabs/ClientListTab';
import PageContainer from '../components/PageContainer';

const ClientAdminPage = () => {
  return (
    <PageContainer>
      <main>
        <Container>
          <Tabs defaultActiveKey="general" id="client-tab" className="mb-3">
            <Tab eventKey="general" title="General">
              <ClientListTab />
            </Tab>
          </Tabs>
        </Container>
      </main>
    </PageContainer>
  );
};

export default ClientAdminPage;
