import React from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PageContainer from '../components/PageContainer';
import UserListByClient from './tabs/UserListByClient';
import UserListtab from './tabs/UserListtab';

const UserAdminPage = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { user } = userInfo;

  return (
    <PageContainer>
      <main>
        <Container>
          <Tabs
            defaultActiveKey={user.role === 'SYSADMIN' ? 'general' : 'client'}
            id="client-tab"
            className="mb-3"
          >
            {user.role === 'SYSADMIN' && (
              <Tab eventKey="general" title="General">
                <UserListtab />
              </Tab>
            )}
            <Tab eventKey="client" title="Por clientes">
              <UserListByClient />
            </Tab>
          </Tabs>
        </Container>
      </main>
    </PageContainer>
  );
};

export default UserAdminPage;
