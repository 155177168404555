import axios from 'axios';
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAIL,
  USER_STATISTICS_REQUEST,
  USER_STATISTICS_SUCCESS,
  USER_STATISTICS_FAIL,
  USER_LIST_CLIENT_COUNT_REQUEST,
  USER_LIST_CLIENT_COUNT_SUCCESS,
  USER_LIST_CLIENT_COUNT_FAIL,
  USER_RECOVERY_REQUEST,
  USER_RECOVERY_SUCCESS,
  USER_RECOVERY_FAIL,
} from '../constants/userConstants';
import { forbiddenInterceptor } from '../utils/functions';

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios({
      method: 'post',
      url: `/api/users/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { email, password },
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({
    type: USER_LOGOUT,
  });
  dispatch({
    type: USER_DETAILS_RESET,
  });
  dispatch({
    type: USER_LIST_RESET,
  });
};

export const register =
  (username, name, lastname, email, phone, role) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const { data } = await axios.post(
        `/api/users`,
        { username, name, lastname, email, phone, role },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      forbiddenInterceptor(error);
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/users/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios({
      method: 'put',
      url: `/api/users/profile`,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      data: user,
    });

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUsers = (pageNumber, filter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const queryPaheNumber = pageNumber ? `pageNumber=${pageNumber}` : '';
    const queryString = `?${queryPaheNumber}&${Object.keys(filter)
      .map((key) => (filter[key] ? `${key}=${filter[key]}` : ''))
      .join('&')}`;

    const { data } = await axios({
      method: 'get',
      url: `/api/users${queryString}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    await axios({
      method: 'delete',
      url: `/api/users/${id}`,
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.put(`/api/users/${user._id}`, user, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: USER_UPDATE_SUCCESS,
    });

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserPassword = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PASSWORD_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.put(`/api/users/change-password`, user, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: USER_PASSWORD_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_PASSWORD_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listClientCountByUsers =
  (pageNumber = 1, filter = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_CLIENT_COUNT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const queryPaheNumber = pageNumber ? `pageNumber=${pageNumber}` : '';
      const queryString = `?${queryPaheNumber}&${Object.keys(filter)
        .map((key) => (filter[key] ? `${key}=${filter[key]}` : ''))
        .join('&')}`;

      const { data } = await axios({
        method: 'get',
        url: `/api/users/clientcount${queryString}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      dispatch({
        type: USER_LIST_CLIENT_COUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      forbiddenInterceptor(error);
      dispatch({
        type: USER_LIST_CLIENT_COUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_STATISTICS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/users/stadistics`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({ type: USER_STATISTICS_SUCCESS, payload: data });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_STATISTICS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const recoverUser = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RECOVERY_REQUEST,
    });

    const { data } = await axios.put(
      `/api/users/reset-password`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    dispatch({
      type: USER_RECOVERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: USER_RECOVERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
