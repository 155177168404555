import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listClientCountByUsers } from '../../actions/userActions';
import ErrorPage from '../ErrorPage';
import ClientListByuserModal from './ClientListByuserModal';
import ClientByUserTable from './UserClientCountTable';

const ClientByUserFilters = () => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [role, setRole] = useState('');
  const [filter, setFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  const userListClientCount = useSelector((state) => state.userListClientCount);
  const { loading, error, userList, page, pages } = userListClientCount;

  const setModalDataAndShow = (id) => {
    setUserId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const filterHandlers = () => {
    setFilter({
      name,
      lastname,
      role,
    });
    setPageNumber(1);
  };

  const clearFiltersHandler = () => {
    setName('');
    setLastname('');
    setRole('');
    setPageNumber(1);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(listClientCountByUsers(pageNumber, filter));
    }, 500);
    return () => {
      clearTimeout(delay);
    };
  }, [dispatch, filter, pageNumber]);

  useEffect(() => {
    filterHandlers();
  }, [name, lastname, role]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <Col xs={12}>
        <Form>
          <Row>
            <Col>
              <p className="h5">Filtros:</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formGridName"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridLastname"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Apellido"
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridRole"
              className="col-6 col-md-2"
            >
              <Form.Control
                as="select"
                type="select"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <option value="">Seleccione un rol</option>
                <option value="VENDEDOR">Vendedor</option>
                <option value="ADMINISTRATIVO">Administrativo</option>
                <option value="SYSADMIN">SysAdmin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword6">
              <Button
                variant="secondary"
                type="button"
                onClick={filterHandlers}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={clearFiltersHandler}
              >
                <FontAwesomeIcon icon={faX} />
              </Button>
            </Form.Group>
          </Row>
        </Form>
        <ClientByUserTable
          loading={loading}
          userList={userList}
          page={page}
          pages={pages}
          onShowModal={setModalDataAndShow}
          onPageChange={setPageNumber}
        />
      </Col>
      <ClientListByuserModal
        show={showModal}
        userId={userId}
        onClose={closeModal}
      />
    </>
  );
};

export default ClientByUserFilters;
