import React, { useEffect, useState } from 'react';
import { Col, Row, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listClientCountByUsers } from '../../actions/userActions';
import { CLIENT_UPDATE_RESET } from '../../constants/clientConstants';
import ToastBox from '../../components/ToastBox';
import ClientFilters from '../../components/clients/ClientFilters';
import ClientStatistics from '../../components/clients/ClientStatistics';

const ClientListTab = () => {
  const dispatch = useDispatch();
  const [notifStatus, setNotifStatus] = useState();

  const clientUpdate = useSelector((state) => state.clientUpdate);
  const { success, error: errorUpdate } = clientUpdate;

  useEffect(() => {
    if (success) {
      setNotifStatus({
        message: 'Los datos del cliente se han actualizado.',
        type: 'success',
      });
    }
  }, [success]);

  useEffect(() => {
    if (errorUpdate) {
      setNotifStatus({
        message:
          'No se pudieron guardar los datos del cliente. En caso de persistir el problema, contácte con el administrador de sistema',
        type: 'danger',
      });
    }
  }, [errorUpdate]);

  useEffect(() => {
    dispatch({ type: CLIENT_UPDATE_RESET });
    dispatch(listClientCountByUsers());
  }, [dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <ToastContainer
          position="bottom-end"
          className="p-5 position-fixed"
          style={{ position: 'fixed', bottom: 0 }}
        >
          {notifStatus && (
            <ToastBox
              message={notifStatus.message}
              type={notifStatus.type}
              key={new Date().toString()}
            />
          )}
        </ToastContainer>
      </Col>
      <Col xs={12}>
        <section className="heading pt-3 pb-3 px-3">
          <h1>Clientes</h1>
          <p>Listado de todos los clientes importates desde homologación.</p>
        </section>
      </Col>
      <ClientStatistics />
      <ClientFilters />
    </Row>
  );
};

export default ClientListTab;
