import React from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';

const Paginate = ({ pages, page, onPaginate }) => {
  const pageNumbers = [];
  for (let index = page - 4; index <= page + 4; index += 1) {
    if (index > 0 && index <= pages) {
      pageNumbers.push(index);
    }
  }

  return (
    pages > 1 && (
      <Row className="py-2">
        <Col xs={12} className="d-flex flex-row justify-content-center">
          <Pagination size="lg">
            <Pagination.First
              disabled={page === 1}
              onClick={() => onPaginate(1)}
            />
            <Pagination.Prev
              disabled={page === 1}
              onClick={() => onPaginate(page - 1)}
            />
            {pageNumbers.map((x) => {
              return (
                <Pagination.Item
                  key={x}
                  active={x === page}
                  onClick={() => onPaginate(x)}
                >
                  {x}
                </Pagination.Item>
              );
            })}
            <Pagination.Next
              disabled={page === pages}
              onClick={() => onPaginate(page + 1)}
            />
            <Pagination.Last
              disabled={page === pages}
              onClick={() => onPaginate(pages)}
            />
          </Pagination>
        </Col>
      </Row>
    )
  );
};

export default Paginate;
