import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listClientsByUser } from '../../../actions/clientActions';
import ErrorPage from '../../ErrorPage';
import ClienListByUserTable from './ClienListByUserTable';

const ClienListByUserFIlter = ({ userId }) => {
  const [filter, setFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [fantasyName, SetFantasyName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [clientCode, setClientCode] = useState('');

  const dispatch = useDispatch();

  const clientListByUser = useSelector((state) => state.clientListByUser);
  const { loading, error, clients, page, pages } = clientListByUser;

  useEffect(() => {
    dispatch(listClientsByUser(pageNumber, { userId, ...filter }));
  }, [filter, pageNumber]);

  useEffect(() => {
    setFilter({
      fantasyName,
      businessName,
      documentNumber,
      code: clientCode,
    });
    setPageNumber(1);
  }, [fantasyName, businessName, documentNumber, clientCode]);

  const filterHandlers = () => {
    setFilter({
      fantasyName,
      businessName,
      documentNumber,
      code: clientCode,
    });
    setPageNumber(1);
  };

  const clearFiltersHandler = () => {
    SetFantasyName('');
    setBusinessName('');
    setDocumentNumber('');
    setClientCode('');
    setPageNumber(1);
  };

  const clientCodeHandler = (value) => {
    const reg = /^\d+$/;
    if (reg.test(value) || value === '') {
      setClientCode(value);
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <>
      <Col xs={12}>
        <Form>
          <Row>
            <Col>
              <p className="h5">Filtros:</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formGridCode"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Código"
                value={clientCode}
                onChange={(e) => {
                  clientCodeHandler(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridFantasyName"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Nombre de fantasía"
                value={fantasyName}
                onChange={(e) => {
                  SetFantasyName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridBusinessName"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Razón social"
                value={businessName}
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridDocument"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Documento"
                value={documentNumber}
                onChange={(e) => {
                  setDocumentNumber(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword6">
              <Button
                variant="secondary"
                type="button"
                onClick={filterHandlers}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={clearFiltersHandler}
              >
                <FontAwesomeIcon icon={faX} />
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </Col>
      <ClienListByUserTable
        clients={clients}
        page={page}
        pages={pages}
        onPageChange={setPageNumber}
        loading={loading}
      />
    </>
  );
};

export default ClienListByUserFIlter;
