import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ClientByUserFilters from '../../components/clients/UserClientCountFilters';
import UserCllentsCountStadistics from '../../components/clients/UserCllentsCountStadistics';
import DailyUpdatesLineChart from '../../components/DailyUpdatesLineChart';

const UserListByClient = () => {
  return (
    <Row>
      <Col xs={12}>
        <section className="heading pt-3 pb-3 px-3">
          <h1>Clientes por usuarios</h1>
          <p>
            Listado de los usuarios con la cantidad de clientes actualizados.
          </p>
        </section>
      </Col>
      <UserCllentsCountStadistics />
      <DailyUpdatesLineChart />
      <ClientByUserFilters />
    </Row>
  );
};

export default UserListByClient;
