import React, { useEffect, useState } from 'react';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers } from '../../actions/userActions';
import UserListTable from './UserListTable';
import ErrorPage from '../ErrorPage';

const UserFilters = () => {
  const dispatch = useDispatch();

  const [userFilter, setUserFIlter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [lastnameFilter, setLastnameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState({});

  const userList = useSelector((state) => state.userList);
  const { loading, users, error, page, pages } = userList;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success } = userUpdate;
  useEffect(() => {
    dispatch(listUsers(pageNumber, filter));
  }, [dispatch, pageNumber, filter, success]);

  useEffect(() => {
    setFilter({
      username: userFilter,
      name: nameFilter,
      lastname: lastnameFilter,
      email: emailFilter,
      role: roleFilter,
    });
    setPageNumber(1);
  }, [userFilter, nameFilter, lastnameFilter, emailFilter, roleFilter]);

  const filterUserHandlers = () => {
    setFilter({
      username: userFilter,
      name: nameFilter,
      lastname: lastnameFilter,
      email: emailFilter,
      role: roleFilter,
    });
    setPageNumber(1);
  };

  const clearFiltersHandler = () => {
    setUserFIlter('');
    setNameFilter('');
    setLastnameFilter('');
    setEmailFilter('');
    setRoleFilter('');
    setPageNumber(1);
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <Col xs={12}>
        <Form>
          <Row>
            <Col>
              <p className="h5">Filtros:</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formGridUser"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Usuario"
                value={userFilter}
                onChange={(e) => {
                  setUserFIlter(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridName"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={nameFilter}
                onChange={(e) => {
                  setNameFilter(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridLastname"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Apellido"
                value={lastnameFilter}
                onChange={(e) => {
                  setLastnameFilter(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridEmail"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Email"
                value={emailFilter}
                onChange={(e) => {
                  setEmailFilter(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridRole"
              className="col-6 col-md-2"
            >
              <Form.Control
                as="select"
                type="select"
                value={roleFilter}
                onChange={(e) => {
                  setRoleFilter(e.target.value);
                }}
              >
                <option value="">Seleccione un rol</option>
                <option value="VENDEDOR">Vendedor</option>
                <option value="ADMINISTRATIVO">Administrativo</option>
                <option value="SYSADMIN">SysAdmin</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridReset">
              <Button
                variant="secondary"
                type="button"
                onClick={filterUserHandlers}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={clearFiltersHandler}
              >
                <FontAwesomeIcon icon={faX} />
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </Col>
      <UserListTable
        users={users}
        page={page}
        pages={pages}
        changePageHandler={setPageNumber}
        loading={loading}
        filter={filter}
      />
    </>
  );
};

export default UserFilters;
