import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import Loader from '../Loader';
import Paginate from '../Paginate';

const ClientByUserTable = ({
  userList = [],
  page,
  pages,
  onShowModal,
  onPageChange,
  loading,
}) => {
  const pageChangeHandler = (newPage = 1) => {
    onPageChange(newPage);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Col xs={12} className="d-flex flex-row-reverse">
        <p>{`Pàgina ${page || 0} de ${pages || 0}`}</p>
      </Col>
      <Col xs={12}>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr className="table-dark">
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Rol</th>
              <th>Cant. Clientes</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {userList &&
              userList.length > 0 &&
              userList.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.lastname}</td>
                  <td>{user.role}</td>
                  <td>{user.clientUpdatedCount}</td>
                  <td>
                    <Button
                      variant="secondary"
                      type="button"
                      className="btn-sm"
                      onClick={() => onShowModal(user._id)}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </td>
                </tr>
              ))}
            {userList && userList.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">
                  Sin resultados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Paginate page={page} pages={pages} onPaginate={pageChangeHandler} />
      </Col>
    </>
  );
};

export default ClientByUserTable;
