import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatistics } from '../../actions/userActions';
import CardPlaceholder from '../CardPlaceholder';
import Message from '../Message';

const UserStatistics = () => {
  const dispatch = useDispatch();

  const userStatistics = useSelector((state) => state.userStatistics);
  const { loading, error, statistics } = userStatistics;

  const numberFormatter = Intl.NumberFormat('es', { notation: 'compact' });

  useEffect(() => {
    dispatch(getUserStatistics());
  }, [dispatch]);

  if (loading) {
    return (
      <>
        <CardPlaceholder />
        <CardPlaceholder />
        <CardPlaceholder />
      </>
    );
  }

  const { totalUsers, activeUsers, inactiveClients } = statistics;

  if (error) {
    return (
      <Message title="Error de sistema" variant="danger">
        <p>No se ha podido obtener estadísticas de usuarios</p>
        <p>Detalles:</p>
        <p>{error}</p>
      </Message>
    );
  }

  return (
    <Col xs={12} className="py-3">
      <Row>
        <Col xs={12} md={4}>
          <Card>
            <Card.Body>
              <p className="h5" style={{ color: '#2980b9' }}>
                Total Usuarios
              </p>
              <p>{numberFormatter.format(totalUsers)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <Card.Body>
              <p className="h5" style={{ color: '#16a085' }}>
                Usuarios Activos
              </p>
              <p>{numberFormatter.format(activeUsers)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <Card.Body>
              <p className="h5" style={{ color: '#f39c12' }}>
                Usuarios Inactivos
              </p>
              <p>{numberFormatter.format(inactiveClients)}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default UserStatistics;
