import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  ToastContainer,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClientByCode } from '../actions/clientActions';
import Loader from '../components/Loader';
import PageContainer from '../components/PageContainer';
import ToastBox from '../components/ToastBox';
import {
  CLIENT_BY_CODE_RESET,
  CLIENT_UPDATE_RESET,
} from '../constants/clientConstants';

const Homepage = () => {
  const [validated, setValidated] = useState(false);
  const [notifStatus, setNotifStatus] = useState();
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const clientByCode = useSelector((state) => state.clientByCode);
  const { loading, client, error } = clientByCode;

  const clientUpdate = useSelector((state) => state.clientUpdate);
  const { success, error: errorUpdate } = clientUpdate;

  useEffect(() => {
    if (success) {
      setNotifStatus({
        message: 'Los datos del cliente se han actualizado.',
        type: 'success',
      });
    }
  }, [success]);

  useEffect(() => {
    if (errorUpdate) {
      setNotifStatus({
        message:
          'No se pudieron guardar los datos del cliente. En caso de persistir el problema, contácte con el administrador de sistema',
        type: 'danger',
      });
    }
  }, [errorUpdate]);

  useEffect(() => {
    if (error) {
      setNotifStatus({
        message: 'No se ha podido encontrar el cliente.',
        type: 'warning',
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch({ type: CLIENT_BY_CODE_RESET });
    dispatch({ type: CLIENT_UPDATE_RESET });
  }, [dispatch]);

  useEffect(() => {
    if (client && client.code === parseInt(code, 10)) {
      navigate(`/clients/${client.code}`);
    }
  }, [client, code, navigate]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      setNotifStatus();
      event.stopPropagation();
    } else {
      dispatch(getClientByCode(code));
    }
    setValidated(true);
  };

  if (loading) {
    return (
      <PageContainer>
        <main>
          <Container>
            <Row>
              <Col className="pt-3 pb-3 px-3">
                <Loader />
              </Col>
            </Row>
          </Container>
        </main>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <main>
        <Container>
          <Row style={{ maxWidth: '100%' }}>
            <ToastContainer position="bottom-end" className="p-5">
              {notifStatus && (
                <ToastBox
                  message={notifStatus.message}
                  type={notifStatus.type}
                  key={new Date().toString()}
                />
              )}
            </ToastContainer>
            <Col xs={12} className="text-center">
              <section className="heading pt-3 pb-3 px-3">
                <h1>&nbsp;Bienvenido a Lincoln</h1>
                <p>
                  Para empezar a actualizar los datos del cliente, ingrese el
                  código del cliente a procesar. Si existe el cliente indicado,
                  se mostrará el formulario con los datos del cliente a
                  completar.
                </p>
              </section>
            </Col>
            <Col xs={12}>
              <section className="form">
                <Form
                  style={{ textAlign: 'left' }}
                  className="px-3"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <Row className="d-flex justify-content-md-center">
                    <Form.Group
                      as={Col}
                      className="mb-3 col-12 col-md-6"
                      controlId="formCode"
                    >
                      <Form.Control
                        value={code}
                        type="text"
                        placeholder="Código"
                        required
                        maxLength={200}
                        onChange={(e) => {
                          setNotifStatus();
                          setCode(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="d-flex justify-content-md-center">
                    <Col xs={1}>
                      <Button variant="primary" type="submit">
                        Buscar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </section>
            </Col>
          </Row>
        </Container>
      </main>
    </PageContainer>
  );
};

export default Homepage;
