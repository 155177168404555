import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Message from './Message';
import Loader from './Loader';

const SecureRoute = ({ onlyAdmin, children }) => {
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else if (
      userInfo.user.role !== 'ADMINISTRATIVO' &&
      userInfo.user.role !== 'SYSADMIN' &&
      onlyAdmin
    ) {
      navigate('/');
    }
  }, [userInfo, onlyAdmin, navigate]);

  if (loading || !userInfo) {
    return <Loader />;
  }

  if (error) {
    return <Message variant="danger">{error}</Message>;
  }

  return children;
};

SecureRoute.defaultProps = {
  onlyAdmin: true,
  children: null,
};

export default SecureRoute;
