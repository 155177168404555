import React from 'react';
import { Image } from 'react-bootstrap';
import loader1gif from '../resources/loaders/Preloader_1.gif';
import loader2gif from '../resources/loaders/Preloader_2.gif';
import loader3gif from '../resources/loaders/Preloader_3.gif';
import loader4gif from '../resources/loaders/Preloader_4.gif';
import loader5gif from '../resources/loaders/Preloader_5.gif';
import loader6gif from '../resources/loaders/Preloader_6.gif';

const loaders = [
  loader1gif,
  loader2gif,
  loader3gif,
  loader4gif,
  loader5gif,
  loader6gif,
];

const Loader = () => {
  const getRandomLoader = () => {
    return loaders[Math.floor(Math.random() * 4) + 1];
  };

  return (
    <Image
      src={getRandomLoader()}
      style={{
        width: '128px',
        height: '128px',
        margin: 'auto',
        display: 'block',
      }}
    />
  );
};

export default Loader;
