import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getClientByCode, updateClient } from '../actions/clientActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import PageContainer from '../components/PageContainer';

const ClientFormPage = () => {
  const [validated, setValidated] = useState(false);
  const [fantasyName, SetFantasyName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [docType, setDocType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [checkDigit, setCheckDigit] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [sector, setSector] = useState('');
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const clientCode = params.code;
  const redirectAdmin = searchParams.get('redirectAdmin');

  const dispatch = useDispatch();

  const clientByCode = useSelector((state) => state.clientByCode);
  const { loading, client, error } = clientByCode;

  const clientUpdate = useSelector((state) => state.clientUpdate);
  const {
    loading: loadingUpdate,
    client: clientUpdated,
    error: errorUpdate,
  } = clientUpdate;

  useEffect(() => {
    if (clientUpdated) {
      if (redirectAdmin) {
        navigate('/admin/clients?updated=ok');
      } else {
        navigate('/?updated=ok');
      }
    }
  }, [clientUpdated, navigate, redirectAdmin]);

  useEffect(() => {
    if (client && client.code === parseInt(clientCode, 10)) {
      SetFantasyName(client.fantasyName);
      setBusinessName(client.businessName);
      setDocType(client.docType);
      setDocumentNumber(client.documentNumber);
      setCheckDigit(client.checkDigit);
      setEmail(client.email);
      if (client.phoneNumber1) {
        setPhoneNumber1(
          client.phoneNumber1.replace('+595', '').replace(/^0/, '')
        );
      }
      if (client.phoneNumber2) {
        setPhoneNumber2(
          client.phoneNumber2.replace('+595', '').replace(/^0/, '')
        );
      }
      setSector(client.sector);
    } else {
      dispatch(getClientByCode(clientCode));
    }
  }, [clientCode, client, dispatch]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(
        updateClient({
          code: clientCode,
          fantasyName: fantasyName.trim(),
          businessName: businessName.trim(),
          docType,
          documentNumber: documentNumber.trim(),
          checkDigit: checkDigit.trim(),
          email: email.trim(),
          phoneNumber1:
            phoneNumber1.trim() === '' ? '' : `+595${phoneNumber1.trim()}`,
          phoneNumber2:
            phoneNumber2.trim() === '' ? '' : `+595${phoneNumber2.trim()}`,
          sector,
        })
      );
    }
    setValidated(true);
  };

  const returnHomeHandler = () => {
    if (redirectAdmin) {
      navigate('/admin/clients');
    } else {
      navigate('/');
    }
  };

  return (
    <PageContainer>
      <main>
        <Container>
          <Row style={{ maxWidth: '100%' }}>
            <Col xs={12}>
              <Breadcrumb>
                {redirectAdmin ? (
                  <BreadcrumbItem href={redirectAdmin ? '/admin/clients' : '/'}>
                    Listado clientes
                  </BreadcrumbItem>
                ) : (
                  <BreadcrumbItem href="/">Inicio</BreadcrumbItem>
                )}
                <BreadcrumbItem active>Clientes</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs={12}>
              <section className="heading pt-5 pb-3 px-3">
                <h1>Formulario de Clientes</h1>
                <p>
                  Aquí se presentan los datos del cliente que se encuentran
                  registrados. Verifique la validez de los campos y actualice
                  aquellos que sean necesarios. Una vez guardados los datos,
                  estos pasarán a formar parte de los datos permanentes del
                  cliente. Como estos datos serán utilizados para la generación
                  de facturas eletrónicas, deben de ser datos exactos.
                </p>
              </section>
            </Col>
            <Col xs={12}>
              {error && <Message variant="danger">{error}</Message>}
            </Col>
            <Col xs={12}>
              {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
            </Col>
            <Col xs={12}>
              {loading || loadingUpdate ? (
                <Loader />
              ) : (
                <section className="form">
                  <Form
                    style={{ textAlign: 'left' }}
                    className="px-3"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-3" controlId="formFantasyname">
                      <Form.Label>Nombre de fantasía</Form.Label>
                      <Form.Control
                        value={fantasyName}
                        type="text"
                        placeholder="Nombre de fantasía"
                        required
                        maxLength={400}
                        onChange={(e) =>
                          SetFantasyName(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBusinessname">
                      <Form.Label>Razón social</Form.Label>
                      <Form.Control
                        value={businessName}
                        type="text"
                        placeholder="Razón social"
                        required
                        maxLength={400}
                        onChange={(e) =>
                          setBusinessName(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDoctype">
                      <Form.Label>Tipo de documento</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        type="select"
                        value={docType}
                        onChange={(e) => setDocType(e.target.value)}
                      >
                        <option value="">Seleccione tipo de documento</option>
                        <option value="RUC">RUC</option>
                        <option value="CI">CI</option>
                        <option value="PASAPORTE">PASAPORTE</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDocumentnumber">
                      <Form.Label>Número de documento</Form.Label>
                      <Form.Control
                        value={documentNumber}
                        type="text"
                        placeholder="Número de documento"
                        required
                        maxLength={100}
                        onChange={(e) =>
                          setDocumentNumber(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDV">
                      <Form.Label>Digito verificador</Form.Label>
                      <Form.Control
                        value={checkDigit}
                        type="text"
                        placeholder="Digito verificador"
                        required={docType === 'RUC'}
                        maxLength={1}
                        onChange={(e) => setCheckDigit(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={email}
                        type="email"
                        placeholder="Email"
                        required
                        maxLength={400}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPhonenumber1">
                      <Form.Label>Número de teléfono 1</Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend1">
                          +595
                        </InputGroup.Text>
                        <Form.Control
                          value={phoneNumber1}
                          type="text"
                          placeholder="Número de teléfono 1"
                          required
                          maxLength={400}
                          onChange={(e) => setPhoneNumber1(e.target.value)}
                          pattern="^\(?([0-9]{2,5})\)?[-. ]?([0-9]{2,5})[-. ]?([0-9]{2,5})$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Obligatorio. Formato incorrecto.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPhonenumber2">
                      <Form.Label>Número de teléfono 2</Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend2">
                          +595
                        </InputGroup.Text>
                        <Form.Control
                          value={phoneNumber2}
                          type="text"
                          placeholder="Número de teléfono 2"
                          maxLength={400}
                          onChange={(e) => setPhoneNumber2(e.target.value)}
                          pattern="^\(?([0-9]{2,5})\)?[-. ]?([0-9]{2,5})[-. ]?([0-9]{2,5})$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Formato incorrecto.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSector">
                      <Form.Label>Rubro</Form.Label>
                      <Form.Control
                        value={sector}
                        type="text"
                        placeholder="Sector"
                        required
                        maxLength={400}
                        onChange={(e) =>
                          setSector(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSubmit">
                      <Button variant="primary" type="submit">
                        Guardar
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="mx-2"
                        onClick={returnHomeHandler}
                      >
                        Volver
                      </Button>
                    </Form.Group>
                  </Form>
                </section>
              )}
            </Col>
          </Row>
        </Container>
      </main>
    </PageContainer>
  );
};

export default ClientFormPage;
