import React from 'react';
import { faCheck, faEdit, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../actions/userActions';
import Paginate from '../Paginate';
import Loader from '../Loader';

const UserListTable = ({
  users = [],
  page,
  pages,
  changePageHandler,
  loading,
}) => {
  const dispatch = useDispatch();

  const changeUserStatusHandler = (_id, status) => {
    dispatch(updateUser({ _id, isActive: !status }));
  };

  const pageChangeHandler = (newPage = 1) => {
    changePageHandler(newPage);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Col xs={12} className="d-flex flex-row-reverse">
        <p>{`Pàgina ${page} de ${pages}`}</p>
      </Col>
      <Col xs={12}>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr className="table-dark">
              <th>Usuario</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Activo</th>
              <th>Rol</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.length > 0 &&
              users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.name}</td>
                  <td>{user.lastname}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.isActive ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faX} color="red" />
                    )}
                  </td>
                  <td>{user.role}</td>
                  <td>
                    <LinkContainer to={`/admin/users/${user._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </LinkContainer>
                    {user.isActive ? (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() =>
                          changeUserStatusHandler(user._id, user.isActive)
                        }
                      >
                        <FontAwesomeIcon icon={faX} />
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        className="btn-sm"
                        onClick={() =>
                          changeUserStatusHandler(user._id, user.isActive)
                        }
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            {users && users.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  Sin resultados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Paginate page={page} pages={pages} onPaginate={pageChangeHandler} />
      </Col>
    </div>
  );
};

export default UserListTable;
