import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

const Message = ({ variant, children, title, dismissible }) => {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert
        variant={variant}
        onClose={() => setShow(false)}
        dismissible={dismissible}
      >
        {title && <Alert.Heading>{title}</Alert.Heading>}
        {children}
      </Alert>
    );
  }
  return '';
};

Message.defaultProps = {
  variant: 'info',
  children: null,
  title: null,
};

export default Message;
