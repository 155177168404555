import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Paginate from '../Paginate';
import Loader from '../Loader';

const ClientListTable = ({
  clients = [],
  page,
  pages,
  changePageHandler,
  loading,
}) => {
  const navigate = useNavigate();

  const dateFormatter = Intl.DateTimeFormat('es', {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  const redirectToEdit = (code) => {
    navigate(`/clients/${code}?redirectAdmin=true`);
  };

  const pageChangeHandler = (newPage = 1) => {
    changePageHandler(newPage);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Col xs={12} className="d-flex flex-row-reverse">
        <p>{`Pàgina ${page} de ${pages}`}</p>
      </Col>
      <Col xs={12}>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr className="table-dark">
              <th>Código</th>
              <th>Nombre Fantasía</th>
              <th>Razón Social</th>
              <th>Documento</th>
              <th>Email</th>
              <th>Teléfono 1</th>
              <th>Teléfono 2</th>
              <th>Sector</th>
              <th>Actualización</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {clients &&
              clients.length > 0 &&
              clients.map((client) => (
                <tr key={client._id}>
                  <td>{client.code}</td>
                  <td>{client.fantasyName}</td>
                  <td>{client.businessName}</td>
                  <td>{`${client.docType || ''} - ${
                    client.documentNumber
                  }`}</td>
                  <td>{client.email}</td>
                  <td>{client.phoneNumber1}</td>
                  <td>{client.phoneNumber2}</td>
                  <td>{client.sector}</td>
                  <td>
                    {client.modified
                      ? dateFormatter.format(new Date(client.updatedAt))
                      : ''}
                  </td>
                  <td>
                    <Button
                      variant="secondary"
                      type="button"
                      className="btn-sm"
                      onClick={() => redirectToEdit(client.code)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                </tr>
              ))}
            {clients && clients.length === 0 && (
              <tr>
                <td colSpan={10} className="text-center">
                  Sin resultados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Paginate page={page} pages={pages} onPaginate={pageChangeHandler} />
      </Col>
    </>
  );
};

export default ClientListTable;
