import React from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Header from './Header';

const PageContainer = ({ children }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <>
      {userInfo && <Header />}
      <main>
        <Container className="py-3">{children}</Container>
      </main>
    </>
  );
};

export default PageContainer;
