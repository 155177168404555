export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGIN_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_PASSWORD_UPDATE_REQUEST = 'USER_PASSWORD_UPDATE_REQUEST';
export const USER_PASSWORD_UPDATE_SUCCESS = 'USER_PASSWORD_UPDATE_SUCCESS';
export const USER_PASSWORD_UPDATE_FAIL = 'USER_PASSWORD_UPDATE_FAIL';
export const USER_PASSWORD_UPDATE_RESET = 'USER_PASSWORD_UPDATE_RESET';

export const USER_STATISTICS_REQUEST = 'USER_STATISTICS_REQUEST';
export const USER_STATISTICS_SUCCESS = 'USER_STATISTICS_SUCCESS';
export const USER_STATISTICS_FAIL = 'USER_STATISTICS_FAIL';

export const USER_LIST_CLIENT_COUNT_REQUEST = 'USER_LIST_CLIENT_COUNT_REQUEST';
export const USER_LIST_CLIENT_COUNT_SUCCESS = 'USER_LIST_CLIENT_COUNT_SUCCESS';
export const USER_LIST_CLIENT_COUNT_FAIL = 'USER_LIST_CLIENT_COUNT_FAIL';
export const USER_LIST_CLIENT_COUNT_RESET = 'USER_LIST_CLIENT_COUNT_RESET';

export const USER_RECOVERY_REQUEST = 'USER_RECOVERY_REQUEST';
export const USER_RECOVERY_SUCCESS = 'USER_RECOVERY_SUCCESS';
export const USER_RECOVERY_FAIL = 'USER_RECOVERY_FAIL';
export const USER_RECOVERY_RESET = 'USER_RECOVERY_RESET';
