export const CLIENT_BY_CODE_REQUEST = 'CLIENT_BY_CODE_REQUEST';
export const CLIENT_BY_CODE_SUCCESS = 'CLIENT_BY_CODE_SUCCESS';
export const CLIENT_BY_CODE_FAIL = 'CLIENT_BY_CODE_FAIL';
export const CLIENT_BY_CODE_RESET = 'CLIENT_BY_CODE_RESET';

export const CLIENT_BY_USER_REQUEST = 'CLIENT_BY_USER_REQUEST';
export const CLIENT_BY_USER_SUCCESS = 'CLIENT_BY_USER_SUCCESS';
export const CLIENT_BY_USER_FAIL = 'CLIENT_BY_USER_FAIL';
export const CLIENT_BY_USER_RESET = 'CLIENT_BY_USER_RESET';

export const CLIENT_DETAILS_REQUEST = 'CLIENT_DETAILS_REQUEST';
export const CLIENT_DETAILS_SUCCESS = 'CLIENT_DETAILS_SUCCESS';
export const CLIENT_DETAILS_FAIL = 'CLIENT_DETAILS_FAIL';
export const CLIENT_DETAILS_RESET = 'CLIENT_DETAILS_RESET';

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_FAIL = 'CLIENT_UPDATE_FAIL';
export const CLIENT_UPDATE_RESET = 'CLIENT_UPDATE_RESET';

export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL';
export const CLIENT_LIST_RESET = 'CLIENT_LIST_RESET';

export const CLIENT_STATISTICS_REQUEST = 'CLIENT_STATISTICS_REQUEST';
export const CLIENT_STATISTICS_SUCCESS = 'CLIENT_STATISTICS_SUCCESS';
export const CLIENT_STATISTICS_FAIL = 'CLIENT_STATISTICS_FAIL';

export const CLIENT_STADISTICS_DAILY_REQUEST =
  'CLIENT_STADISTICS_DAILY_REQUEST';
export const CLIENT_STADISTICS_DAILY_SUCCESS =
  'CLIENT_STADISTICS_DAILY_SUCCESS';
export const CLIENT_STADISTICS_DAILY_FAIL = 'CLIENT_STADISTICS_DAILY_FAIL';
