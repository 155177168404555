import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listClients } from '../../actions/clientActions';
import ErrorPage from '../ErrorPage';
import ClientListTable from './ClientListTable';

const ClientFilters = () => {
  const dispatch = useDispatch();

  const [fantasyName, SetFantasyName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [email, setEmail] = useState('');
  const [sector, setSector] = useState('');
  const [modified, setModified] = useState('');
  const [clientCode, setClientCode] = useState('');
  const [filter, setFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const clientList = useSelector((state) => state.clientList);
  const { loading, clients, error, page, pages } = clientList;

  useEffect(() => {
    dispatch(listClients(pageNumber, filter));
  }, [dispatch, filter, pageNumber]);

  useEffect(() => {
    setFilter({
      fantasyName,
      businessName,
      documentNumber,
      email,
      sector,
      modified,
      code: clientCode,
    });
    setPageNumber(1);
  }, [
    fantasyName,
    businessName,
    documentNumber,
    email,
    sector,
    modified,
    clientCode,
  ]);

  const filterHandlers = () => {
    setFilter({
      fantasyName,
      businessName,
      documentNumber,
      email,
      sector,
      modified,
      code: clientCode,
    });
    setPageNumber(1);
  };

  const clearFiltersHandler = () => {
    SetFantasyName('');
    setBusinessName('');
    setDocumentNumber('');
    setEmail('');
    setSector('');
    setModified();
    setClientCode('');
    setPageNumber(1);
  };

  const clientCodeHandler = (value) => {
    const reg = /^\d+$/;
    if (reg.test(value) || value === '') {
      setClientCode(value);
    }
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <Col xs={12}>
        <Form>
          <Row>
            <Col>
              <p className="h5">Filtros:</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formGridCode"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Código"
                value={clientCode}
                onChange={(e) => {
                  clientCodeHandler(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridFantasyName"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Nombre de fantasía"
                value={fantasyName}
                onChange={(e) => {
                  SetFantasyName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridBusinessName"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Razón social"
                value={businessName}
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridDocument"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Documento"
                value={documentNumber}
                onChange={(e) => {
                  setDocumentNumber(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridEmail"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridStatus"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Sector"
                value={sector}
                onChange={(e) => {
                  setSector(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridUser"
              className="col-12 col-md-2 mb-3"
            >
              <Form.Control
                as="select"
                type="select"
                value={modified}
                onChange={(e) => {
                  setModified(e.target.value);
                }}
              >
                <option value="">Estado del cliente</option>
                <option value="true">Actualizado</option>
                <option value="false">No actualizado</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword6">
              <Button
                variant="secondary"
                type="button"
                onClick={filterHandlers}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={clearFiltersHandler}
              >
                <FontAwesomeIcon icon={faX} />
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </Col>
      <ClientListTable
        clients={clients}
        page={page}
        pages={pages}
        changePageHandler={setPageNumber}
        loading={loading}
      />
    </>
  );
};

export default ClientFilters;
