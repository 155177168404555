import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ClienListByUserFIlter from './modal/ClienListByUserFIlter';

const ClientListByuserModal = ({ show, userId, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>Listado de clientes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ClienListByUserFIlter userId={userId} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClientListByuserModal;
