import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { recoverUser } from '../actions/userActions';
import { USER_RECOVERY_RESET } from '../constants/userConstants';
import Loader from './Loader';
import Message from './Message';
import TitleSection from './TitleSection';

const RecoveryForm = ({ onRecovery }) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const userRecovery = useSelector((state) => state.userRecovery);
  const { loading, error, message } = userRecovery;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(recoverUser(email));
    }
    setValidated(true);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: USER_RECOVERY_RESET });
    };
  }, []);

  if (loading) {
    return (
      <>
        <TitleSection
          title="Recupera tu cuenta"
          description="Podemos ayudarte a recuperar tu cuenta. Primero escribe tu cuenta de correo y te enviaremos más instrucciones."
        />
        <Loader />
      </>
    );
  }

  if (error) {
    return (
      <>
        <TitleSection
          title="Recupera tu cuenta"
          description="Podemos ayudarte a recuperar tu cuenta. Primero escribe tu cuenta de correo y te enviaremos más instrucciones."
        />
        <Message variant="danger">{error}</Message>
        <div className="d-flex flex-row justify-content-center">
          <Button variant="link" onClick={onRecovery}>
            Volver
          </Button>
        </div>
      </>
    );
  }

  if (message) {
    return (
      <>
        <TitleSection
          title="Recupera tu cuenta"
          description="Podemos ayudarte a recuperar tu cuenta. Primero escribe tu cuenta de correo y te enviaremos más instrucciones."
        />
        <Message variant="success">{message}</Message>
        <div className="d-flex flex-row justify-content-center">
          <Button variant="link" onClick={onRecovery}>
            Volver
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <TitleSection
        title="Recupera tu cuenta"
        description="Podemos ayudarte a recuperar tu cuenta. Primero escribe tu cuenta de correo y te enviaremos más instrucciones."
      />
      <section className="form">
        <Form
          style={{ textAlign: 'left' }}
          className="px-3"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="Email"
              placeholder="Email"
              maxLength={100}
              required
            />
            <Form.Control.Feedback type="invalid">
              Obligatorio. Debe ser una dirección email válida.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex flex-row justify-content-center">
            <Button variant="primary" type="submit">
              Reestablecer contraseña
            </Button>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <Button variant="link" onClick={onRecovery}>
              Volver
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default RecoveryForm;
