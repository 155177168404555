import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SecureRoute from './components/SecureRoute';
import AboutPage from './pages/AboutPage';
import ClientFormPage from './pages/ClientFormPage';
import ClientAdminPage from './pages/ClientAdminPage';
import Homepage from './pages/Homepage';
import LoginPage from './pages/LoginPage';
import MyAccountPage from './pages/MyAccountPage';
import NotFoundPage from './pages/NotFoundPage';
import RegisterPage from './pages/RegisterPage';
import UserEditPage from './pages/UserEditPage';
import UserAdminPage from './pages/UserAdminPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/clients/:code"
          element={
            <SecureRoute onlyAdmin={false}>
              <ClientFormPage />
            </SecureRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <SecureRoute onlyAdmin={false}>
              <MyAccountPage />
            </SecureRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <SecureRoute onlyAdmin>
              <UserAdminPage />
            </SecureRoute>
          }
        />
        <Route
          path="/admin/register"
          element={
            <SecureRoute onlyAdmin>
              <RegisterPage />
            </SecureRoute>
          }
        />
        <Route
          path="/admin/users/:id/edit"
          element={
            <SecureRoute onlyAdmin>
              <UserEditPage />
            </SecureRoute>
          }
        />
        <Route
          path="/"
          element={
            <SecureRoute onlyAdmin={false}>
              <Homepage />
            </SecureRoute>
          }
        />
        <Route
          path="/admin/clients"
          element={
            <SecureRoute onlyAdmin>
              <ClientAdminPage />
            </SecureRoute>
          }
        />
        <Route
          path="/about"
          element={
            <SecureRoute onlyAdmin={false}>
              <AboutPage />
            </SecureRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
