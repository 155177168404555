import React from 'react';

const TitleSection = ({ title, description }) => {
  return (
    <section className="heading pt-5 pb-3 px-3">
      <h1>{title}</h1>
      <p>{description}</p>
    </section>
  );
};

export default TitleSection;
