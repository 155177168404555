import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';

const ToastBox = ({ message, type }) => {
  const [show, setShow] = useState(false);

  let title = 'Éxito';

  switch (type) {
    case 'danger':
      title = 'Error';
      break;
    case 'warning':
      title = 'Alerta';
      break;
    case 'info':
      title = 'Info';
      break;
    default:
      title = 'Éxito';
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      setShow(true);
    }, 500);
    return () => {
      clearTimeout(delay);
    };
  }, []);

  return (
    <Toast
      onClose={() => setShow(false)}
      show={show}
      delay={5000}
      autohide
      bg={type}
    >
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
        <small>Ahora</small>
      </Toast.Header>
      <Toast.Body
        className={
          type === 'dark' || type === 'success' || type === 'danger'
            ? 'text-white'
            : ''
        }
      >
        <p className="py-3">{message}</p>
      </Toast.Body>
    </Toast>
  );
};

export default ToastBox;
