import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Message from './Message';

const ErrorPage = ({ error }) => {
  const refreshPageHandler = () => {
    window.location.reload();
  };

  return (
    <Row>
      <Col xs={12}>
        <Message title="Error de sistema" variant="danger" dismissible={false}>
          <p>
            El servidor se ha encontrado con un error interno o una
            configuración incorrecta y no pudo completar la petición.
          </p>
          <p>Detalles:</p>
          <p>{error}</p>
        </Message>
      </Col>
      <Col xs={12} className="d-flex flex-row justify-content-center">
        <Button variant="primary" type="button" onClick={refreshPageHandler}>
          <FontAwesomeIcon icon={faRefresh} />
          &nbsp;Recargar
        </Button>
      </Col>
    </Row>
  );
};

export default ErrorPage;
