import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChartD3 = ({ title, data, colors, labels }) => {
  const d3Chart = useRef();

  useEffect(() => {
    const width = 400;
    const height = 200;
    const padding = 60;

    const radius = Math.min(width, height) / 2 - padding / 2;

    const svg = d3
      .select(d3Chart.current)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .append('g')
      .attr('transform', 'translate(0,0)');

    const color = d3.scaleOrdinal(colors);

    svg
      .append('text')
      .attr('x', 0)
      .attr('y', 10)
      .text(title)
      .style('font-size', '15px')
      .attr('alignment-baseline', 'middle');

    // Generate the pie
    const pie = d3.pie();

    // Generate the arcs
    const arc = d3
      .arc()
      .innerRadius(radius - 20)
      .outerRadius(radius);

    // Generate groups
    const arcs = svg
      .selectAll('arc')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('transform', `translate(${width / 2 - 100} ,${height / 2})`)
      .attr('class', 'arc');

    // Draw arc paths
    arcs
      .append('path')
      .attr('fill', (d, i) => color(i))
      .attr('d', arc);

    labels.forEach((label, index) => {
      svg
        .append('circle')
        .attr('cx', 200)
        .attr('cy', 50 + index * 20)
        .attr('r', 6)
        .style('fill', colors[index]);
      svg
        .append('text')
        .attr('x', 220)
        .attr('y', 50 + index * 20)
        .text(label)
        .style('font-size', '10px')
        .attr('alignment-baseline', 'middle');
    });
  }, [title, data, colors, labels]);

  return (
    <div className="mt-3">
      <svg ref={d3Chart} />
    </div>
  );
};

PieChartD3.defaultProps = {
  title: 'PieChart',
  data: [0],
  colors: ['#4daf4a', '#377eb8', '#ff7f00', '#984ea3', '#e41a1c'],
  labels: ['A', 'B'],
};

export default PieChartD3;
