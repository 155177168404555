import axios from 'axios';
import {
  CLIENT_BY_CODE_FAIL,
  CLIENT_BY_CODE_REQUEST,
  CLIENT_BY_CODE_SUCCESS,
  CLIENT_BY_USER_FAIL,
  CLIENT_BY_USER_REQUEST,
  CLIENT_BY_USER_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_STADISTICS_DAILY_FAIL,
  CLIENT_STADISTICS_DAILY_REQUEST,
  CLIENT_STADISTICS_DAILY_SUCCESS,
  CLIENT_STATISTICS_FAIL,
  CLIENT_STATISTICS_REQUEST,
  CLIENT_STATISTICS_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
} from '../constants/clientConstants';
import { forbiddenInterceptor } from '../utils/functions';

export const getClientByCode = (code) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_BY_CODE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/client/${code}`, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: CLIENT_BY_CODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: CLIENT_BY_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getClientDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/client/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: CLIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: CLIENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateClient = (client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.put(`/api/client/${client.code}`, client, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listClients =
  (pageNumber, filter) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const queryPaheNumber = pageNumber ? `pageNumber=${pageNumber}` : '';
      const queryString = `?${queryPaheNumber}&${Object.keys(filter)
        .map((key) => (filter[key] ? `${key}=${filter[key]}` : ''))
        .join('&')}`;

      const { data } = await axios({
        method: 'get',
        url: `/api/client${queryString}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      dispatch({
        type: CLIENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      forbiddenInterceptor(error);
      dispatch({
        type: CLIENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getClientStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_STATISTICS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/client/stadistics`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({ type: CLIENT_STATISTICS_SUCCESS, payload: data });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: CLIENT_STATISTICS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getClientStadisticsDaily = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_STADISTICS_DAILY_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/client/stadistics/daily`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({ type: CLIENT_STADISTICS_DAILY_SUCCESS, payload: data });
  } catch (error) {
    forbiddenInterceptor(error);
    dispatch({
      type: CLIENT_STADISTICS_DAILY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listClientsByUser =
  (pageNumber = 1, filter = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_BY_USER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const queryPaheNumber = pageNumber ? `pageNumber=${pageNumber}` : '';
      const queryString = `?${queryPaheNumber}&${Object.keys(filter)
        .map((key) => (filter[key] ? `${key}=${filter[key]}` : ''))
        .join('&')}`;

      const { data } = await axios({
        method: 'get',
        url: `/api/client/clientsByUser${queryString}`,
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      dispatch({
        type: CLIENT_BY_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      forbiddenInterceptor(error);
      dispatch({
        type: CLIENT_BY_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
