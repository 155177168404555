import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { register } from '../actions/userActions';
import PageContainer from '../components/PageContainer';

const RegisterPage = () => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [userName, setUsername] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userRegistered } = userRegister;

  useEffect(() => {
    if (userRegistered && userRegistered._id) {
      navigate('/admin/users');
    }
  }, [userRegistered, navigate, dispatch]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      dispatch(
        register(
          userName.trim(),
          name.trim(),
          lastname.trim(),
          email.trim(),
          phone.trim(),
          role.trim()
        )
      );
    }
    setValidated(true);
  };

  const returnHomeHandler = () => {
    navigate('/admin/users');
  };

  return (
    <PageContainer>
      <main>
        <Container>
          <Row style={{ maxWidth: '100%' }}>
            <Col xs={12}>
              <Breadcrumb>
                <BreadcrumbItem href="/admin/users">Usuarios</BreadcrumbItem>
                <BreadcrumbItem active>Registrar</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs={12}>
              <section className="heading pt-5 pb-3 px-3">
                <h1>Registrar Usuario</h1>
                <p>
                  Complete los sigu8ientes datos para poder crear una nueva
                  cuenta Lincoln.
                </p>
              </section>
              {error && <Message variant="danger">{error}</Message>}
              {loading ? (
                <Loader />
              ) : (
                <section className="form">
                  <Form
                    style={{ textAlign: 'left' }}
                    className="px-3"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={email}
                        type="email"
                        placeholder="Email"
                        required
                        maxLength={200}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio. Debe ser un correo válido.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formUsername">
                      <Form.Label>Usuario</Form.Label>
                      <Form.Control
                        value={userName}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                        placeholder="Nombre de usuario"
                        required
                        minLength={8}
                        maxLength={100}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio. Debe tener entre 8 y 50 carácteres.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="Nombre"
                        required
                        maxLength={200}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio. Màximo 200 carácteres.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formLastname">
                      <Form.Label>Apellido</Form.Label>
                      <Form.Control
                        value={lastname}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        placeholder="Apellido"
                        required
                        maxLength={200}
                      />
                      <Form.Control.Feedback type="invalid">
                        Obligatorio. Màximo 200 carácteres.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formRole">
                      <Form.Label>Rol</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        type="select"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="">Seleccione un rol</option>
                        <option value="VENDEDOR">Vendedor</option>
                        <option value="ADMINISTRATIVO">Administrativo</option>
                        <option value="SYSADMIN">SysAdmin</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Label>Teléfono</Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">
                          +595
                        </InputGroup.Text>
                        <Form.Control
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          placeholder="21 111 111"
                          max={100}
                          required
                          pattern="^\(?([0-9]{2,3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Obligatorio. Debe de seguir el formato indicado.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="form-group">
                      <Button variant="primary" type="submit">
                        Registrar
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="mx-2"
                        onClick={returnHomeHandler}
                      >
                        Volver
                      </Button>
                    </div>
                  </Form>
                </section>
              )}
            </Col>
          </Row>
        </Container>
      </main>
    </PageContainer>
  );
};

export default RegisterPage;
