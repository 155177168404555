import {
  CLIENT_BY_CODE_FAIL,
  CLIENT_BY_CODE_REQUEST,
  CLIENT_BY_CODE_RESET,
  CLIENT_BY_CODE_SUCCESS,
  CLIENT_BY_USER_FAIL,
  CLIENT_BY_USER_REQUEST,
  CLIENT_BY_USER_RESET,
  CLIENT_BY_USER_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_RESET,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_RESET,
  CLIENT_LIST_SUCCESS,
  CLIENT_STADISTICS_DAILY_FAIL,
  CLIENT_STADISTICS_DAILY_REQUEST,
  CLIENT_STADISTICS_DAILY_SUCCESS,
  CLIENT_STATISTICS_FAIL,
  CLIENT_STATISTICS_REQUEST,
  CLIENT_STATISTICS_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_RESET,
  CLIENT_UPDATE_SUCCESS,
} from '../constants/clientConstants';

export const clientByCodeReducer = (state = { client: {} }, action) => {
  switch (action.type) {
    case CLIENT_BY_CODE_REQUEST:
      return { ...state, loading: true };
    case CLIENT_BY_CODE_SUCCESS:
      return { loading: false, client: action.payload };
    case CLIENT_BY_CODE_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_BY_CODE_RESET:
      return {};
    default:
      return state;
  }
};

export const clientDetailsReducer = (state = { client: {} }, action) => {
  switch (action.type) {
    case CLIENT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CLIENT_DETAILS_SUCCESS:
      return { loading: false, client: action.payload };
    case CLIENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const clientUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_UPDATE_REQUEST:
      return { loading: true };
    case CLIENT_UPDATE_SUCCESS:
      return { loading: false, success: true, client: action.payload };
    case CLIENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const clientListReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return { loading: true };
    case CLIENT_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case CLIENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_LIST_RESET:
      return { clients: [] };
    default:
      return state;
  }
};

export const clientListByUserReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case CLIENT_BY_USER_REQUEST:
      return { loading: true };
    case CLIENT_BY_USER_SUCCESS:
      return { loading: false, ...action.payload };
    case CLIENT_BY_USER_FAIL:
      return { loading: false, error: action.payload };
    case CLIENT_BY_USER_RESET:
      return { clients: [] };
    default:
      return state;
  }
};

export const clientStatisticsReducer = (
  state = {
    statistics: {
      totalClients: 0,
      updatedClients: 0,
      pendingClients: 0,
      percentageUpdated: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case CLIENT_STATISTICS_REQUEST:
      return { loading: true };
    case CLIENT_STATISTICS_SUCCESS:
      return { loading: false, statistics: action.payload };
    case CLIENT_STATISTICS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const clientStadisticsDailyReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_STADISTICS_DAILY_REQUEST:
      return { loading: true };
    case CLIENT_STADISTICS_DAILY_SUCCESS:
      return { loading: false, dailyStatistics: action.payload };
    case CLIENT_STADISTICS_DAILY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
